import { AddressResult } from 'src/app/address-search/address-search.component';

export class Customer {
  fullyAuthenticated: boolean;
  email?: string;
  registration?: string;
  businessPartnerID?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phone?: Phone;
  address?: AddressResult;
  isResidential?: boolean;
  typeOfProperty?: TypeOfProperty;
  isOwner?: boolean;
  addedPlans: string[] = []

  constructor(customer: Customer = undefined
  ) {
    if (customer) {
      this.fullyAuthenticated = customer.fullyAuthenticated;
      this.email = customer.email;
      this.businessPartnerID;
      this.password = customer.password;
      this.firstName = customer.firstName;
      this.lastName = customer.lastName;
      this.phone = customer.phone;
      this.address = customer.address;
      this.isResidential = customer.isResidential;
      this.typeOfProperty = customer.typeOfProperty;
      this.isOwner = customer.isOwner;
      this.registration = customer.registration;
      this.addedPlans = customer.addedPlans;
    } else {
      this.fullyAuthenticated = false;
      this.email = undefined;
      this.businessPartnerID;
      this.password = undefined;
      this.firstName = undefined;
      this.lastName = undefined;
      this.phone = undefined;
      this.address = undefined;
      this.isResidential = undefined;
      this.typeOfProperty = undefined;
      this.isOwner = undefined;
      this.addedPlans = [];
      this.registration = undefined;
    }
  }

  public prettyAddress(): string {
    const address = this.address?.street + ' ' + this.address?.city + ', ' + this.address?.state + ' ' + this.address?.zipCode;
    return address.includes('undefined') ? undefined : address
    }
}

export class Phone {
  number: string;
  type: string;

  constructor(number: string = undefined, type: string = '') {
    this.number = number;
    this.type = type;
  }
}

export enum TypeOfProperty {
  singleFamily = 'Single Family',
  multiFamily = 'Multi-family',
  mobileHome = 'Mobile Home'
}