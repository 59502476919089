import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take } from 'rxjs';
import { LocalAWSCartResponse, LocalAWSCartRequest } from 'src/app/Models/cart.models';
import { SignInResponse } from 'src/app/Models/SignInResponse';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { Customer } from 'src/app/shared/models/customer';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})

export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {
  isFormValid: boolean;
  passwordValue: string;
  emailValue: string;
  customer: Customer;
  protected _onDestroy = new Subject<void>();


  constructor(
    private _toastService: ToastService,
    private _router: Router,
    private _apiService: ApiService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _customerService: CustomerService,
    private _loaderService: LoaderService
  ) { }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.performSignIn();
    }
  }

  ngOnInit(): void {
    this._customerService.customer.pipe(take(1)).subscribe(customer => {
      this.customer = customer;
    });
    this.emailValue = this.customer.email;
  }

  public performSignIn(): void {
    if (this.isFormValid === true) {
      this._loaderService.requestShowLoader();
      this.checkRegistration();
    } else {
      this._loaderService.requestHideLoader();
      this._googleAnalyticsService.sendVPV('sign-in/error/email-or-password-are-incorrect');
      this._toastService.triggerErrorToast('Please check email or password');
    }
  }

  // before the user signs in - lets make sure their email is registered
  public checkRegistration(): void {
    this._loaderService.requestShowLoader();
    const currentEmail: string = this.emailValue;

    this._apiService.isRegistered(currentEmail).pipe(take(1)).subscribe({
      next: resp => {
        this._customerService.updateRegistrationStatus(resp);
        if (resp == 'NotRegistered') {
          this._customerService.updateEmail(currentEmail);
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/create-profile']);
        }
        else if (resp == 'Registered') {
          this._customerService.updateEmail(currentEmail);
          this.actualSignIn();
        }
        else if (resp == 'Pending') {
          this._customerService.updateEmail(currentEmail);
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/verify-email']);
        }
        else {
          this._loaderService.requestHideLoader();
          this._toastService.triggerErrorToast('Error verifying the provided email.');
        }
      },
      error: _ => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Network Error via Sign In. Please try again.');
      },
    });
  }

  actualSignIn(): void {
    this._apiService.signInCustomer(this.emailValue, this.passwordValue).pipe(take(1)).subscribe({
      next: response => {
        this._loaderService.requestHideLoader();
        this.handleLoginResponse(response);
      },
      error: _ => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Email or password are incorrect');
      },
      complete: () => {
        this._loaderService.requestHideLoader();
      },
    });
  }

  handleLoginResponse(response: SignInResponse): void {
    const customer: Customer = this.customer ?? new Customer();
    customer.email = response.EmailLoginId;
    customer.businessPartnerID = response.BpID;
    this._customerService.updateCustomer(customer);
    this.updateLocalCartIfNeeded(response, customer);
    this._router.navigate(['checkout/transfer']);
  }

  updateLocalCartIfNeeded(response:SignInResponse, customer:Customer): void {
   if(customer.addedPlans.length > 0) {
      /// user is a returning customer
      console.log('Skipping Cart Check');
      return;
   }

    this._apiService.getLocalAWSCart(response.EmailLoginId).pipe(take(1)).subscribe(cartResponse => {
       const request:LocalAWSCartRequest = this.converted(cartResponse);
       if(request.products.length > 0) {

        // pre-populate the card with the AWS content
        for(const productID of request.products ) {
          this._customerService.addPlan(productID);
        }

        // Update the AWS cart with the business partner ID if not already there.
        if(request.businessPartnerId == undefined && !request.openedInSAP) {
          console.log('Found local cart not opened in SAP - updating businessPartnerId');
          request.businessPartnerId = response.BpID;
          this._apiService.updateAWSLocalCartWithRequest(request).pipe(take(1)).subscribe(updateResponse => {
              console.log(updateResponse);
          });
         }
       }
    });
  }

  converted(response:LocalAWSCartResponse): LocalAWSCartRequest {
    const request:LocalAWSCartRequest = { 
      'openedInSAP': response.opened_in_sap,
      'zip': response.zip,
      'isResidential':response.is_residential,
      'address': response.address,
      'businessPartnerId':response.business_partner_id,
      'email': response.email,
      'state': response.state,
      'address2': response.address_2,
      'city': response.city,
      'sapCartId': response.sap_cart_id,
      'products': response.products,
      'propertyType': response.property_type,
       'ownRent': response.own_or_rent
      };

      return request;
  }

  ngAfterViewInit(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
  }

  public updateUserEmail($event): void {
    this.emailValue = $event;
  }

  public updateUserPassword($event): void {
    this.passwordValue = $event;
  }

  public updateFormValidation($event): void {
    this.isFormValid = $event;
  }

  public goBack(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
    this._router.navigate(['checkout/home']);
  }

  public goto(url: string): void {
    window.open(url, '_blank');
  }

  public register(): void {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/register');
    window.open('https://p-auth.duke-energy.com/my-account/registration-r5');
  }

  public needHelpClick() {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    window.open('https://www.duke-energy.com/need-help-sign-in', '_blank');
  }

  public forgotUsernameClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-username');
    window.open('https://www.duke-energy.com/forgot-username', '_blank');
  }

  public forgotPasswordClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-password');
    window.open('https://p-auth.duke-energy.com/my-account/sign-in/forgot-password', '_blank');
  }

  // TODO: still in need of? 
  public eventClick(event) {
    if ((event.target.innerHTML).indexOf('help') >= 0) {
      //  this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
