import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-modal-message',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent {
  @Output() secondaryAction = new EventEmitter();
  @Output() primaryAction = new EventEmitter();

  @Input() title:string = ''
  @Input() message: string = ''

  @Input() primaryTitle: string = 'Continue'
  @Input() secondaryTitle: string = 'Previous'

  constructor(private router: Router, private _customerService: CustomerService) {}

  public onClickSecondary(): void {
    this.secondaryAction.emit();
  }

  onClickPrimary(): void {
    this.primaryAction.emit();
  }
}
