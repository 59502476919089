import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AddressResult, AddressSearchComponent } from 'src/app/address-search/address-search.component';
import { ModalMessageComponent } from 'src/app/components/modal-message/modal-message.component';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-location-check',
  standalone: true,
  imports: [SharedModule, AddressSearchComponent, ModalMessageComponent],
  templateUrl: './location-check.component.html',
  styleUrl: './location-check.component.scss'
})
export class LocationCheckComponent implements OnInit, OnDestroy {
 @ViewChild('modalTemplate') locationModal: any;

  eligForm: FormGroup;
  public residentialControl = new FormControl('residential');
  public isModalOpen = false;
  isResidential = undefined;
  typeOfProperty = undefined;
  isOwner = undefined;
  userAddress: AddressResult = undefined;
  protected _onDestroy = new Subject<void>();
  hasAddedPlans = false;

  

  constructor(
    private _router: Router,
    private _customerService: CustomerService,
    private route: ActivatedRoute,
    private _toastService: ToastService) {
      this.buildForm();
  }

  buildForm(): void {
    this.eligForm = new FormGroup({
      residential: new FormControl(''),
      typeOfProperty: new FormControl(''),
      ownOrRent: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      params.getAll('plan').forEach((plan) => {
        this.onProductCodeFound(plan);
      });
    });
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
      //Not sure what this error is but research has begun.
      this.hasAddedPlans = cust?.addedPlans?.length > 0 ?? false;
    })
  }

  
  answerForAddress(address: AddressResult): void {
    this.userAddress = address;
  }

  answerForResidentialQuestion(event: string): void {
    this.isResidential = !event.includes('Non-Res');
  }

  onProductCodeFound(plan: string){
    this._customerService.addPlanByAbbreviatedId(plan);
  }

  answerForTypeOfPropertyQuestion(event: string): void {
    // TODO: replace with enum 
    if (event.includes('Single')) {
      this.typeOfProperty = 'Single Family'
    } else if (event.includes('Multi')) {
      this.typeOfProperty = 'Multi-family'
    } else if (event.includes('Mobile')) {
      this.typeOfProperty = 'Mobile Home'
    }
  }

  answerForOwnOrRentQuestion(event: string): void {
    this.isOwner = event.includes('Own');
  }

  guardForValidAddress(): boolean {
    return (!this.userAddress || !this.userAddress?.zipCode || !this.userAddress?.city || !this.userAddress?.street || !this.userAddress?.state) ? true : false;
  }

  guardForAllAnswersCompleted(): boolean {
    return (this.isResidential === undefined || this.typeOfProperty === undefined || this.isOwner === undefined) ? true : false;
  }

  public continueActionClicked(): void {
    if (this.guardForValidAddress()) {
     
      this._toastService.triggerErrorToast('Please enter a valid address.');
    } else if (this.guardForAllAnswersCompleted()) {
      
      this._toastService.triggerErrorToast('Please answer the questions above.')
    } else {
      this._customerService.updateEligibility(this.userAddress, this.isResidential, this.typeOfProperty, this.isOwner)
      this.isModalOpen = true;
      
    }
  
  }

  public previousActionClicked() {
    this._router.navigate(['home']);
  }
 
  public edit(): void {
    this.isModalOpen = false;
  }

  public continue(): void {
    if(this.hasAddedPlans){
       this._router.navigate(['shopping-cart']);
    }
   else {
    this._router.navigate(['home']);
   }
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
