/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { HomeContent } from './Models/HomeContent';
import { DataSource } from './Models/DataSource'
import { Subscription, filter } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { Toast, ToastService } from './services/toast.service';
import { EdsActionButton, EdsNavGroup } from '@electronds/ng-components-duke';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @Input() navGroup: EdsNavGroup[] = [];
  @Input() currentNavGroup!: EdsNavGroup;
  public showNavChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  public homeContent: HomeContent = DataSource.homeContent();
  public headerDrawerIsOpen = false;
  public isLoading = false;
  public toast = {} as Toast;
  public showToast = false;
  public hideNav: string = '';
  public showNav = false;
  public title = 'All Plans';

  currentUrl = '';


  protected subscriptions = new Array<Subscription>();

  constructor(
    private _loaderService: LoaderService,
    private _toastService: ToastService,
    private _apiService: ApiService,
    private _router: Router) {

    }


  ngOnInit(): void {
    this.subscriptions.push(
      this._loaderService.showLoader().subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
      this._toastService.toast
        .pipe(filter((toast) => toast.message !== ''))
        .subscribe((toast) => this.handleToast(toast))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = new Array<Subscription>();
  }

  public oEconicLogoClick(): void {
    window.location.href = 'https://www.duke-energy.com/home';
  }

  public handleToast(toast: Toast): void {
    this.toast = { ...toast };
    this.showToast = true;
  }

  public onDukeLogoClick(): void {
    window.location.href = 'https://www.duke-energy.com/home';
  }

  public headerActionButtons: EdsActionButton[] = [
    {
      label: 'Cart',
      icon: {
        path: 'assets/icons/shopping-cart.svg',
        sizeClass: 'icon-28',
        attributes: {
          fill: '#00789e',
        },
      },
      action: () => alert('I work!'),
    },
    {
      label: 'Sign In',
      icon: {
        path: 'assets/icons/individual-base.svg',
        sizeClass: 'icon-24',
        attributes: {
          fill: '#00789e',
        },
      },
      action: () => alert('I work!'),
    },
  ];

  public headerNavGroups: EdsNavGroup[] = [
    {
      label: 'All Plans',
      children: [
        [
          {
            label: 'Home Wiring Repair',
            url: '',
          },
          {
            label: 'Surge Coverage & Grounding',
            url: '',
          },
          {
            label: 'Water Heater Repair',
            url: '',
          }
        ]
      ],
    }
  ];

  public handleHeaderClicked(): void {
    this.showNav = !this.showNav;
    this.showNavChanged.emit(this.showNav);
  }
 
}
