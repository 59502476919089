import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer, Phone, TypeOfProperty } from '../shared/models/customer';
import { AddressResult } from '../address-search/address-search.component';
import { DataSource } from 'src/app/Models/DataSource';
import { Tier } from '../Models/PlansContent';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public tiers: Tier[] = [];
  private _customer$ = new BehaviorSubject<Customer>(null);
  private _customer = new Customer();

  constructor() {
    const raw = sessionStorage.getItem('customer');
    const customer = new Customer(JSON.parse(raw));
    
    if(raw != undefined && customer != undefined) {
      this._customer =   customer;
      this.broadcastAccountChanges();
    }
   }

  public resetCustomer(): void {
    this._customer = new Customer();
    this.broadcastAccountChanges();
  }

  get customer(): Observable<Customer> {
    return this._customer$.asObservable();
  }

  get isFullyAuthenticated(): boolean {
    return this._customer.fullyAuthenticated
  }


  updateEligibility(address: AddressResult, isResidential: boolean, typeOfProperty: TypeOfProperty, isOwner: boolean): void {
    this._customer.address = address;
    this._customer.isResidential = isResidential;
    this._customer.typeOfProperty = typeOfProperty;
    this._customer.isOwner = isOwner;
    this.broadcastAccountChanges();
  }

  public updateIsFullyAuthenticated(isFullAuth: boolean): void {
    this._customer.fullyAuthenticated = isFullAuth;
    this.broadcastAccountChanges();
  }

  public updateEmail(email: string): void {
    this._customer.email = email;
    this.broadcastAccountChanges();
  }

  public updateRegistrationStatus(status: string): void {
    this._customer.registration = status;
    this.broadcastAccountChanges();
  }

  public updateName(firstName: string, lastName): void {
    this._customer.firstName = firstName;
    this._customer.lastName = lastName;
    this.broadcastAccountChanges();
  }

  public updatePassword(password: string): void {
    this._customer.password = password;
    this.broadcastAccountChanges();
  }

  public updatePhone(phone: Phone): void {
    this._customer.phone = phone;
    this.broadcastAccountChanges();
  }

  public updateCustomer(customer: Customer): void {
    this._customer = customer;
    this.broadcastAccountChanges();
  }

  public addPlan(planId: string): string[] {
    // TODO: maybe a check for the correct ID here to be double safe? 
    if (planId && !this._customer?.addedPlans?.includes(planId)) {
      this._customer?.addedPlans?.push(planId);
      this.broadcastAccountChanges();
    }
    return this._customer.addedPlans;
  }

  // this could now be redundant of the func above since we are using  the abbreivated id now
  public addPlanByAbbreviatedId(abbreviatedId: string): string[] {
    const plan = DataSource.getPlanDetailByID(abbreviatedId);
    return this.addPlan(plan?.abbreviatedId ?? null)
  }

  public removePlan(id: string){
    const index = this._customer?.addedPlans?.indexOf(id);
    if (index !== -1) {
      this._customer?.addedPlans?.splice(index, 1);
    }
    this.broadcastAccountChanges();
  }

 

  private broadcastAccountChanges(): void {
    sessionStorage.setItem('customer', JSON.stringify(this._customer));
    this._customer$.next(this._customer);
  }
}
