import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisclaimerComponent } from '../../disclaimer-component/disclaimer.component';
import { DataSource } from '../../Models/DataSource';
import { Plan } from '../../Models/PlansContent';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, fromEvent, takeUntil } from 'rxjs';
import { HomeContent } from '../../Models/HomeContent';
import { ToastService } from '../../services/toast.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-tier',
  standalone: true,
  imports: [DisclaimerComponent, CommonModule, SharedModule],
  templateUrl: './tier.component.html',
  styleUrl: './tier.component.scss',
})
export class WhichTierComponent implements OnInit, OnDestroy {
  public homeContent: HomeContent = DataSource.homeContent();
  plan: Plan;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  public isNotMobileLayout = false;
  public selectedItem = -1;
  protected _onDestroy = new Subject<void>();
  private _subs: Subscription[] = []


  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _customerService: CustomerService
  ) { }

  ngOnInit(): void {
    // TODO: unsubscribe safely
    this._subs.push(this.route.queryParams.subscribe(_ => {
      this.formatPlan();
    }),
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(_ => {
    })
  )
  }

  formatPlan(): void {
    const whatPlanRouteParam = this.route.snapshot.queryParams['plan'] as string;
    this.plan = DataSource.specificPlan(whatPlanRouteParam);

    this.isNotMobileLayout = window.innerWidth >= 768 ? true : false;

    this.resizeObservable$ = fromEvent(window, 'resize')
    this._subs.push(this.resizeSubscription$ = this.resizeObservable$.subscribe(_ => {
      this.isNotMobileLayout = window.innerWidth >= 768 ? true : false;
    }));

    const main = document.getElementById('description')
    main.innerHTML = this.plan.content.planTile.description
  }

  clickOnActionButton(index: number): void {
    this.selectedItem = this.selectedItem == index ? 1 : index;
  }

  onClickTCLink() {
    window.open(this.plan.content.planTile.tcLink, '_blank');
  }

  addPlan(): void {
    // TODO: maybe also check for a selected plan?
    if (this.selectedItem == -1) {
      this._toastService.triggerErrorToast('Please select a plan to move forward');
    } else {
      this._toastService.triggerSuccessToast('Plan successfully added');
      const planID:string = this.plan.content.tiers[this.selectedItem].abbreviatedId;
      this._customerService.addPlan(planID);
      this._router.navigate(['shopping-cart']);
    }
  }

  goPrevious(): void {
    this._router.navigate(['home']);
  }

  ngOnDestroy() {
    this._subs.forEach(s => s.unsubscribe())
  }
}

// TODO: move to models
export interface EdsSelectOption {
  id: number,
  displayText: string,
  value: string,
}

