<div class="px-24">
  <div class="text-center pb-20">
    <h2
      id="bold"
      class="text-3xl md:text-4xl text-blue md:text-left text-center font-bold"
    >
      Shopping Cart
    </h2>
  </div>
</div>


<!--Selected Plans-->
<!-- only show if they have a plan added -->
<div *ngIf="this.customer?.addedPlans?.length > 0" class="md:flex md:gap-32 md:mx-40 mx-10">
  <div class="md:w-3/5">
    <div 
      class="text-md text-customGreyBold md:text-left text-center font-bold border rounded-t-sm border-[#dfe4e4] bg-gray-lighter p-12"
    >
     {{this.customer?.prettyAddress() ?? ''}}
    </div>
    <div
      *ngFor="let plan of tiers"
      class="items-center justify-left pb-12 text-left border-[#dfe4e4] border rounded-b-md md:p-16 p-10 pt-20"
    >
      <div class="flex md:flex-none">
        <img class="icon-80" src="{{ plan.image }}" alt="plan-icons" />
        <div class="flex flex-col md:flex-row w-full">
          <div class="md:w-2/3 flex flex-col">
            <div class="ml-24 text-lg text-customGreyBold font-bold">
              {{ plan.name }}
            </div>
            <div
              class="font-normal text-md md:container-sm mt-6 mb-8 ml-20 m-0 text-gray-dark"
            >
              {{ plan.description }}
            </div>
          </div>
          <div class="md:w-1/3 md:ml-80 flex flex-row gap-10">
            <span class="font-bold text-customGreyBold ml-24">Cost:</span>
            <span class="inline-flex">${{ plan.cost }} per month</span>
          </div>
        </div>
      </div>

      <div class="flex-col md:ml-80 ml-80">
        <eds-button
          [isUnderlined]="true"
          type="tertiary"
          text="Change Plan"
          (buttonClick)="changePlan(plan.id)"
        ></eds-button>
        <eds-button
          [isUnderlined]="true"
          type="tertiary"
          text="Remove"
          (buttonClick)="remove(plan.abbreviatedId)"
        ></eds-button>
      </div>
    </div>
  </div>

  <!--Total Amount-->
  <div class="md:w-2/5 mt-20 md:mt-0">
    <div
      class="text-md text-customGreyBold text-left font-bold border rounded-t-sm border-[#dfe4e4] bg-gray-lighter p-12"
    >
      Order Summary
    </div>
    <div class="border-[#dfe4e4] border rounded-b-md mb-10">
      <div class="flex p-16">
        <p>Subtotal ({{ tiers.length }} Plan)</p>
        <p class="md:ml-auto ml-144 font-bold">$ {{ subTotal }}</p>
      </div>
      <div class="flex ml-16">
        <p>Taxes</p>
        <img
          class="ml-8 cursor-pointer self-start mt-2"
          src="assets/icons/tooltip-base.svg"
          width="16"
          height="16"
          alt="taxes-tooltip"
          (click)="toggleTooltip()"
        />
        <p class="md:ml-auto md:m-6 ml-96">(Calculated at checkout)</p>
      </div>

      <div class="flex border-t-2 border-t-gray-lighter m-12">
        <p class="font-bold md:m-10 mt-10">Estimated Monthly Charges</p>
        <p class="pt-2 text-md font-bold md:ml-auto ml-64 mt-8">
          ${{ monthlyCost }}
        </p>
      </div>
    </div>

    <div class="mx-32">
      <eds-button
        class="md:pt-24"
        [type]="'primary'"
        [text]="'Checkout'"
        (buttonClick)="checkout()"
        extraTwClasses="w-full mt-10"
      >
      </eds-button>
      <eds-button
        [type]="'tertiary'"
        [text]="'Continue Shopping'"
        isUnderlined="true"
        (buttonClick)="continue()"
        extraTwClasses="w-full"
      ></eds-button>
    </div>
  </div>
</div>

<!-- Let user know shopping cart is empty if it is -->
<div *ngIf="this.customer?.addedPlans?.length < 1" class="text-center mb-56">
  <p class="mb-10 text-xl">Your cart is empty.</p>
  <p class="md:mb-32">
    Check out our
    <a href="/home" class="text-teal-darker underline"
      >Home Protection Plans</a
    >
    that we offer.
  </p>
</div>

<!--Recommendation Section-->
<div class="bg-gray-lighter md:pt-24 pt-24 mt-56 mb-auto">
  <div class="">
    <h2 class="text-xl md:text-center text-center">We Also Recommend</h2>
  </div>

  <div
    class="md:grid md:grid-cols-2 items-center justify-center md:gap-14 md:mx-176 md:pt-20 pt-20 hover:cursor-pointer space-x-2 m-32"
  >
    <app-simple-card
      id="gasLine"
      (click)="routeToPlan('/tier?plan=home-wiring-repair-plans')"
      [cardTitle]="'Gas Line Repair'"
      [description]="
        'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'
      "
      [cardIcon]="'/assets/icons/home-wiring-thumbnail-light.svg'"
    >
    </app-simple-card>

    <app-simple-card
      id="appRepair"
      (click)="routeToPlan('/tier?plan=surge-plans')"
      [cardTitle]="'Appliance Repair'"
      [description]="
        'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'
      "
      [cardIcon]="'/assets/icons/home-wiring-thumbnail-light.svg'"
    >
    </app-simple-card>
    <app-simple-card
      id="heatingRepair"
      (click)="routeToPlan('/tier?plan=home-wiring-repair-plans')"
      [cardTitle]="'Heating Repair'"
      [description]="
        'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'
      "
      [cardIcon]="'/assets/icons/water-heater-thumbnail-light.svg'"
    >
    </app-simple-card>
    <app-simple-card
      id="sewerRepair"
      (click)="routeToPlan('/tier?plan=water-heater-repair-plans')"
      [cardTitle]="'Sewer Line Repair'"
      [description]="
        'Lorem ispum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna. Ut enim ad minim.'
      "
      [cardIcon]="'/assets/icons/water-heater-thumbnail-light.svg'"
    >
    </app-simple-card>
  </div>
  <div class="h-[30px]"></div>
</div>

<!-- Modal -->
<eds-modal
  *ngIf="isTooltipClicked"
  (modalClosed)="toggleTooltip()"
  [contentTemplate]="modalTemplate"
  [isOpen]="isTooltipClicked"
  [allowClose]="true"
  primaryBtnText=""
  secondaryBtnText=""
  title=""
></eds-modal>

<ng-template #modalTemplate>
  <div class="gap-16 max-w-xl">
    <ng-container>
      <div class="">
        <div class="rich-text">
          <app-modal-message
            primaryTitle="Close"
            (primaryAction)="closeTooltip()"
            secondaryTitle=""
            (closeModal)="closeTooltip()"
            title="Taxes Tile Goes here"
            message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim."
          ></app-modal-message>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
